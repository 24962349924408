import React, { useState } from 'react';
import { Box, Heading, Text, VStack, HStack, Badge, Icon, SimpleGrid, Stat, StatLabel, StatNumber, StatHelpText, Divider, Menu, MenuButton, MenuList, MenuItem, Button, Portal, Tag, TagLabel, TagCloseButton, Wrap, WrapItem, List, UnorderedList, ListItem } from '@chakra-ui/react';
import { FaStar, FaUsers, FaGlobe, FaFacebook, FaInstagram, FaTwitter, FaCheck, FaTimes, FaMapMarkerAlt, FaPhone, FaGlobeAmericas, FaChevronDown, FaSearch } from 'react-icons/fa';

const BusinessInsightsDashboard = ({ reviewsData }) => {
  const [selectedBusinesses, setSelectedBusinesses] = useState(() => 
    reviewsData ? Array.from({ length: reviewsData.length }, (_, i) => i) : []
  );

  if (!reviewsData || reviewsData.length === 0) {
    return (
      <Box p={4}>
        <Text>No business data available</Text>
      </Box>
    );
  }

  const handleBusinessToggle = (businessId) => {
    setSelectedBusinesses(prev => {
      if (prev.includes(businessId)) {
        return prev.filter(id => id !== businessId);
      } else {
        return [...prev, businessId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedBusinesses.length === reviewsData.length) {
      setSelectedBusinesses([]);
    } else {
      setSelectedBusinesses(reviewsData.map((_, index) => index));
    }
  };

  const handleRemoveBusiness = (index) => {
    setSelectedBusinesses(prev => prev.filter(id => id !== index));
  };

  const SectionHeading = ({ children }) => (
    <HStack spacing={2} mb={4} mt={6}>
      <Divider flex={1} borderColor="gray.300" />
      <Text color="gray.600" fontSize="lg" fontWeight="bold" whiteSpace="nowrap">
        {children}
      </Text>
      <Divider flex={1} borderColor="gray.300" />
    </HStack>
  );

  const MetricItem = ({ icon, label, value, color = "gray.700" }) => (
    <HStack spacing={3}>
      <Icon as={icon} color={color} />
      <Text color="gray.600">{label}:</Text>
      <Text fontWeight="semibold" color={color}>{value}</Text>
    </HStack>
  );

  const SocialMediaStatus = ({ platform, isActive }) => (
    <HStack spacing={2}>
      <Icon 
        as={platform === 'Instagram' ? FaInstagram : platform === 'Facebook' ? FaFacebook : FaTwitter} 
        color={isActive ? (platform === 'Instagram' ? "purple.500" : platform === 'Facebook' ? "blue.500" : "blue.400") : "gray.300"} 
      />
      <Text color={isActive ? "green.500" : "red.500"} fontSize="sm">
        {platform}: {isActive ? "Active" : "Not Found"}
      </Text>
    </HStack>
  );

  const renderMarkdownLine = (line, index, lines) => {
    // Handle headings (##)
    if (line.startsWith('##')) {
      return (
        <Heading 
          key={index} 
          as="h2" 
          size="xl" 
          color="blue.700" 
          borderBottom="2px solid" 
          borderColor="blue.100" 
          pb={2} 
          mt={8} 
          mb={6}
        >
          {line.replace('##', '').trim()}
        </Heading>
      );
    }

    // Handle bullet points with bold sections
    if (line.startsWith('* **') && line.includes(':**')) {
      const [beforeColon, ...afterColon] = line.split(':**');
      const boldText = beforeColon.replace('* **', '').replace('**', '');
      const remainingText = afterColon.join(':**').trim();
      
      return (
        <UnorderedList key={index} ml={8} mb={2}>
          <ListItem color="gray.700" lineHeight="tall">
            <Text as="span" fontWeight="bold" color="blue.600">
              {boldText}:
            </Text>
            {' ' + remainingText}
          </ListItem>
        </UnorderedList>
      );
    }

    // Handle regular bullet points
    if (line.startsWith('*') && !line.includes('**')) {
      // Check if this is the first bullet point
      const isFirstBullet = index === 0 || !lines[index - 1]?.startsWith('*');
      
      // If it's the first bullet point, start a new UnorderedList
      if (isFirstBullet) {
        // Find all consecutive bullet points that don't contain **
        let bulletPoints = [];
        for (let i = index; i < lines.length; i++) {
          const currentLine = lines[i];
          if (!currentLine?.startsWith('*') || currentLine.includes('**')) {
            break;
          }
          bulletPoints.push(currentLine.replace(/^\*\s*/, ''));
        }
        
        if (bulletPoints.length > 0) {
          return (
            <UnorderedList key={index} ml={8} mb={4} spacing={2}>
              {bulletPoints.map((point, i) => (
                <ListItem key={`${index}-${i}`} color="gray.700" lineHeight="tall">
                  {point}
                </ListItem>
              ))}
            </UnorderedList>
          );
        }
      }
      // Skip rendering for subsequent bullet points as they're handled by the UnorderedList above
      return null;
    }

    // Handle regular text
    if (line.trim()) {
      return (
        <Text 
          key={index} 
          mb={4} 
          color="gray.700" 
          lineHeight="tall"
        >
          {line}
        </Text>
      );
    }

    return null;
  };

  const BusinessCard = ({ business }) => (
    <Box bg="white" p={6} borderRadius="lg" boxShadow="sm" w="full">
      <VStack spacing={6} align="stretch">
        {/* Header Section */}
        <HStack justify="space-between" align="flex-start">
          <VStack align="flex-start" spacing={2}>
            <Heading size="lg">{business.name}</Heading>
            <HStack>
              <Icon as={FaStar} color="yellow.400" />
              <Text fontWeight="bold" fontSize="xl">{business.rating_avg}</Text>
              <Text color="gray.500">({business.rating_count} reviews)</Text>
            </HStack>
            <Badge colorScheme="blue" px={3} py={1} borderRadius="full">
              {business.primary_type.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            </Badge>
          </VStack>
        </HStack>

        {/* Stats Grid */}
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
          <Stat p={4} bg="gray.50" borderRadius="lg">
            <StatLabel>Rating</StatLabel>
            <StatNumber>{business.rating_avg}/5</StatNumber>
            <StatHelpText>{business.rating_count} reviews</StatHelpText>
          </Stat>
          <Stat p={4} bg="gray.50" borderRadius="lg">
            <StatLabel>Monthly Traffic</StatLabel>
            <StatNumber>{business.traffic_data?.metrics?.estimated_monthly_visitors || 'N/A'}</StatNumber>
            <StatHelpText>Website Visitors</StatHelpText>
          </Stat>
          <Stat p={4} bg="gray.50" borderRadius="lg">
            <StatLabel>Social Media</StatLabel>
            <HStack spacing={4} mt={2}>
              <Icon as={FaFacebook} color={business.traffic_data?.metrics?.social?.facebook ? "blue.500" : "gray.300"} />
              <Icon as={FaInstagram} color={business.traffic_data?.metrics?.social?.instagram ? "purple.500" : "gray.300"} />
              <Icon as={FaTwitter} color={business.traffic_data?.metrics?.social?.twitter ? "blue.400" : "gray.300"} />
            </HStack>
          </Stat>
        </SimpleGrid>

        {/* Insights Content */}
        {business.insight && (
          <Box 
            bg="white" 
            borderRadius="md" 
            p={6}
          >
            {business.insight.split('\n').map((line, index, lines) => 
              renderMarkdownLine(line, index, lines)
            )}
          </Box>
        )}

        {/* Contact Information */}
        <Box>
          <Divider my={4} />
          <VStack align="stretch" spacing={3}>
            <HStack>
              <Icon as={FaMapMarkerAlt} color="red.500" />
              <Text>{business.address}</Text>
            </HStack>
            <HStack>
              <Icon as={FaPhone} color="green.500" />
              <Text>{business.phone}</Text>
            </HStack>
            {business.website_uri && (
              <HStack>
                <Icon as={FaGlobeAmericas} color="blue.500" />
                <Text>{business.website_uri}</Text>
              </HStack>
            )}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );

  return (
    <Box p={6}>
      <VStack spacing={6} align="stretch">
        {/* Business Selection */}
        <Box bg="white" p={6} borderRadius="lg" boxShadow="sm">
          <VStack align="stretch" spacing={4}>
            <HStack justify="space-between" align="center">
              <VStack align="flex-start" spacing={1}>
                <Heading size="md">Business Insights</Heading>
                <Text color="gray.600" fontSize="sm">Select businesses to view detailed insights and comparisons</Text>
              </VStack>
              <HStack spacing={2}>
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="blue"
                  onClick={handleSelectAll}
                >
                  {selectedBusinesses.length === reviewsData.length ? 'Deselect All' : 'Select All'}
                </Button>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    rightIcon={<FaChevronDown />}
                    colorScheme="blue"
                    size="sm"
                  >
                    {selectedBusinesses.length === 0 
                      ? 'Select Businesses' 
                      : `${selectedBusinesses.length} Selected`}
                  </MenuButton>
                  <Portal>
                    <MenuList maxH="300px" overflowY="auto">
                      {reviewsData.map((business, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => handleBusinessToggle(index)}
                          icon={
                            <Icon
                              as={selectedBusinesses.includes(index) ? FaCheck : FaSearch}
                              color={selectedBusinesses.includes(index) ? "blue.500" : "gray.400"}
                            />
                          }
                        >
                          <HStack justify="space-between" flex={1}>
                            <Text>{business.name}</Text>
                            <HStack spacing={1}>
                              <Icon as={FaStar} color="yellow.400" boxSize={3} />
                              <Text fontSize="sm" color="gray.600">{business.rating_avg}</Text>
                            </HStack>
                          </HStack>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Portal>
                </Menu>
              </HStack>
            </HStack>

            {/* Selected Business Tags */}
            {selectedBusinesses.length > 0 && (
              <Wrap spacing={2} mt={2}>
                {selectedBusinesses.map((index) => (
                  <WrapItem key={index}>
                    <Tag
                      size="md"
                      borderRadius="full"
                      variant="subtle"
                      colorScheme="blue"
                    >
                      <TagLabel>{reviewsData[index].name}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveBusiness(index)} />
                    </Tag>
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </VStack>
        </Box>

        {/* Selected Businesses */}
        {selectedBusinesses.length === 0 ? (
          <Box bg="white" p={8} borderRadius="lg" boxShadow="sm" textAlign="center">
            <Icon as={FaSearch} boxSize={8} color="gray.400" mb={4} />
            <Heading size="md" color="gray.600" mb={2}>No Businesses Selected</Heading>
            <Text color="gray.500">Select one or more businesses from the dropdown above to view insights</Text>
          </Box>
        ) : (
          <SimpleGrid columns={{ base: 1, xl: selectedBusinesses.length > 1 ? 2 : 1 }} spacing={6}>
            {selectedBusinesses.map((index) => (
              <BusinessCard key={index} business={reviewsData[index]} />
            ))}
          </SimpleGrid>
        )}
      </VStack>
    </Box>
  );
};

export default BusinessInsightsDashboard; 